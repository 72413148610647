//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { removePTags } from '~/utils'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    subrubrik() {
      if (!this.data.subrubrik) return ''

      const re = /([\w\d]+)([-/])([\w\d]+)/
      return this.data.subrubrik.replace(re, '$1<wbr>$2$3')
    },
  },

  methods: {
    removePTags(text) {
      return removePTags(text)
    },
  },
}
