var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"youtube-video-player"},[_c('div',{staticClass:"relative w-full h-full"},[_c('div',{staticClass:"relative z-10 w-full h-full bg-center bg-cover",class:{
        'pointer-events-none': !_vm.enableControls,
      },style:({
        backgroundImage: ("linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('" + _vm.videoThumbnail + "')"),
      })},[_c('client-only',[(_vm.controls)?_c('youtube',{ref:"youtubePlayer",staticClass:"absolute w-full h-full",attrs:{"video-id":_vm.youtubeVideoId,"player-width":0,"player-vars":{
            autoplay: Number(_vm.autoplay),
            loop: Number(_vm.loop),
            playlist: _vm.youtubeVideoId,
          },"player-height":0,"mute":_vm.mute},on:{"ready":_vm.ready,"playing":function($event){return _vm.playing($event)},"paused":function($event){return _vm.paused($event)},"ended":function($event){return _vm.ended($event)},"error":function($event){return _vm.error($event)}}}):_vm._e()],1)],1),_vm._v(" "),(!_vm.controls && !_vm.autoplay)?_c('button',{staticClass:"block absolute inset-0 dark:border dark:border-white cursor-pointer",on:{"click":_vm.play}},[_c('div',{staticClass:"sm:w-15 sm:h-15 md:w-25 md:h-25 flex absolute top-1/2 left-1/2 z-20 justify-center items-center w-10 h-10 bg-white bg-opacity-50 rounded-full transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"},[_c('PlayIcon',{staticClass:"w-1/3 h-1/3 text-white"})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }