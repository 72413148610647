//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getIdFromURL } from 'vue-youtube-embed'

export default {
  props: {
    url: {
      type: String,
      default: null,
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    loop: {
      type: Boolean,
      default: false,
    },

    enableControls: {
      type: Boolean,
      default: true,
    },

    mute: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      controls: !this.enableControls,
      videoThumbnail: null,
      initialPlay: true,
      initialTimestamp: 0,
      youtubeInstance: undefined,
    }
  },

  computed: {
    youtubeVideoId() {
      return getIdFromURL(this.url)
    },
  },

  async mounted() {
    if (this.url) {
      this.videoThumbnail = `https://img.youtube.com/vi/${this.youtubeVideoId}/maxresdefault.jpg`
      const imagewidht = await this.getImgWidth()
      // sometimes the youtube max res image is not available
      // it will return instead an error image that has a width of exaclty 120
      // when it returs that error image it should get instead the hqdefault image
      if (imagewidht === 120) {
        this.videoThumbnail = `https://img.youtube.com/vi/${this.youtubeVideoId}/hqdefault.jpg`
      }
    }
    if (this.autoplay) {
      this.play()
    }
  },

  methods: {
    play() {
      this.controls = true
      if (this.$refs.video) {
        this.$refs.video.play()
      }
    },

    seekTo(time) {
      if (this.$refs.youtubePlayer && this.youtubeInstance) {
        this.youtubeInstance.seekTo(time, true)
      } else {
        this.initialTimestamp = time
        this.play()
      }
    },

    ready(event) {
      this.youtubeInstance = event.target
      event.target.seekTo(this.initialTimestamp, true)
      event.target.playVideo()
    },

    getImgWidth() {
      return new Promise((resolve) => {
        const newImg = new Image()

        newImg.onload = function () {
          resolve(newImg.width)
        }

        newImg.src = this.videoThumbnail
      })
    },

    playing(event) {
      const action = this.initialPlay ? 'play' : 'resume'
      this.initialPlay = false
      this.trackEvent(action, event.target)
    },

    paused(event) {
      this.trackEvent('pause', event.target)
    },

    ended(event) {
      this.trackEvent('complete', event.target)
    },

    error(error) {
      this.trackEvent('error', error, 'youtube:' + error)
    },

    trackEvent(action, target, label) {
      if (window && window.dataLayer) {
        const videoData = target.getVideoData()
        label = label || videoData.video_id + ':' + videoData.title

        window.dataLayer.push({
          event: 'youtube',
          action,
          label,
          video_id: videoData.video_id,
          video_title: videoData.title,
          position: target.getCurrentTime().toFixed(),
          total_length: target.getDuration().toFixed(),
          percentage: (
            (target.getCurrentTime() / target.getDuration()) *
            100
          ).toFixed(),
          video_player: 'youtube',
          type: 'video',
        })
      }
    },
  },
}
