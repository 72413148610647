//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },

  methods: {
    genAltText(img) {
      if (img[`alt_${this.$i18n.locale}`])
        return img[`alt_${this.$i18n.locale}`]
      // FALLBACK DE
      return img.alt ? img.alt : ''
    },
  },
}
