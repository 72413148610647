import { render, staticRenderFns } from "./SubrubricSection.vue?vue&type=template&id=9bf135f8&"
import script from "./SubrubricSection.vue?vue&type=script&lang=js&"
export * from "./SubrubricSection.vue?vue&type=script&lang=js&"
import style0 from "./SubrubricSection.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tag: require('/vercel/path0/components/general/Tag.vue').default,VideoPlayer: require('/vercel/path0/components/general/VideoPlayer.vue').default,List: require('/vercel/path0/components/general/List.vue').default,ContentBlockImages: require('/vercel/path0/components/contentBlock/ContentBlockImages.vue').default,Pdf: require('/vercel/path0/components/general/Pdf.vue').default})
